import { AnalyticsEvent } from "components/Analytics/AnalyticsRecorder";
import { TabKey } from "components/BrandPage/BrandPageUI/BrandPageUI";
import {
  GAEvent,
  triggerBingAdsEvent,
  triggerGAEvent,
  triggerMetaEvent,
} from "./ui-utils";

export const triggerBrokerageConnectGAW = () => {
  if (typeof window !== "undefined" && window["gtag"])
    window["gtag"]("event", "conversion", {
      send_to: AnalyticsEvent.PlaidConnectionCreated,
      value: 10.0,
      currency: "USD",
    });
};

// File downloads (brand page and dashboard)
export const triggerDownloadClickEvent = (symbol: string, fileName: string) => {
  triggerGAEvent({
    category: "FileDownload",
    action: `FileDownload-${symbol}`,
    label: `FileDownload-${symbol}-${fileName}`,
  });
};

// On Brand Page
export const triggerSubnavEvent = (tab: TabKey, symbol: string) => {
  triggerGAEvent({
    category: "BrandNavClick",
    action: `BrandNavClick-${symbol}`,
    label: `BrandNavClick-${symbol}-${tab}`,
  });
};

//-- Trade Button
export const triggerTradeButtonEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Trade",
    action: `TradeButton${symbol}Click`,
    label: `${symbol}Trade`,
  });
};

//-- View Website Button
export const triggerViewWebsiteEvent = (symbol: string) => {
  triggerGAEvent({
    category: "BrandWebsite",
    action: `${symbol}WebsiteClick`,
    label: `Visit ${symbol}`,
  });
};

//-- StockPerks Graphic
export const triggerStockPerksGraphicEvent = (symbol: string) => {
  triggerGAEvent({
    category: "StockPerksButton",
    action: `${symbol}StockPerksButtonClick`,
    label: `${symbol} Stock Perks Button`,
  });
};

//-- Learn More link in Callout Banner
export const triggerCalloutBannerEvent = (symbol: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: `${symbol}LearnMoreClick`,
    label: `${symbol}LearnMoreCallout`,
  });
};

export const triggerCTAClick = (location: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: "TiiPerkBanner",
    label: `TiiPerkBanner${location}`,
  });
};

//-- Sub-Brands/Our Brands (if exist)
export const triggerSubBrandEvent = (symbol: string, brand: string) => {
  triggerGAEvent({
    category: "OurBrands",
    action: `${symbol}BrandnameClick`,
    label: `${symbol}Brandname ${brand}`,
  });
};

export const triggerBrandEmailSubmission = (symbol: string) => {
  triggerGAEvent({
    category: "BrandEmailSubmission",
    action: `BrandEmailSubmission-${symbol}`,
    label: `BrandEmailSubmission-${symbol}`,
  });
};

export const triggerVoteCTAClick = (symbol: string) => {
  triggerGAEvent({
    category: "VoteClick",
    action: `VoteCTAClick-${symbol}`,
    label: `VoteCTAClick-${symbol}`,
  });
};

// NOTE: Old Deprecated Brand events after redesign 5/22

//-- Insights Tab:
export const triggerInsightsTabEvent = (symbol: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: `InsightsTab${symbol}Click`,
    label: `${symbol}Insights`,
  });
};

//-- Perks Tab
export const triggerPerksTabEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Perk",
    action: `PerksTab${symbol}Click`,
    label: `${symbol}Perks`,
  });
};

//-- Stock Performance Tab
export const triggerPerformanceTabEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Stock Performance",
    action: `StockPerformanceTab${symbol}Click`,
    label: `${symbol}StockPerformance`,
  });
};

export const triggerStoreTabEvent = (symbol: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: `StoreTab${symbol}Click`,
    label: `${symbol}Store`,
  });
};

// On TiiCKER Perks landing page

//-- New Perks From These Brands (for each of the four tiles)
export const triggerNewPerksEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Perk",
    action: `${symbol}PerksTileClick`,
    label: `${symbol}PerksTilePerksPage`,
  });
};

//-- Perk of the Week
export const triggerPerkOfTheWeekEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Perk",
    action: `${symbol}PerkOftheWeekTileClick`,
    label: `${symbol}PerkOfTheWeekTilePerksPage`,
  });
};

//-- Previous Perks
export const triggerPreviousPerksEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Perk",
    action: `${symbol}PreviousPerkTileClick`,
    label: `${symbol}PerviousPerkTilePerksPage`,
  });
};

export const triggerPerkClaim = (symbol: string, perkTitle: string) => {
  const event: GAEvent = {
    category: "Perk",
    action: `${symbol}PerkClaimed`,
    label: `${symbol}PerkClaimed-${perkTitle}`,
  };

  triggerGAEvent(event);
  triggerBingAdsEvent(event);
  // We have to tie into a default event on Meta because we could not figure out how to do custom events
  // The default event we chose is AddToCart
  // TODO: Figure out how to setup custom events in Meta
  triggerMetaEvent({
    action: "AddToCart",
    category: event.category,
    label: event.label,
  });
};

// Brand Landing Page

export const triggerFeaturedBrandClickEvent = (symbol: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: `FeaturedBrand${symbol}Click`,
    label: `${symbol}FeaturedBrandClick`,
  });
};

// Other

//-- Brand ticker anywhere on site
export const triggerGenericSymbolEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Ticker",
    action: `${symbol}TickerClick`,
    label: `${symbol}Ticker`,
  });
};

//-- Prospective partner visited generated link
export const triggerLeadVisitedEvent = (symbol: string) => {
  triggerGAEvent({
    category: "Partner",
    action: `LeadGen-${symbol}`,
    label: `Partner-${symbol}`,
  });
};

//-- Prospective partner button click
export const partnerFormButtonClickEvent = () => {
  triggerGAEvent({
    category: "CTAclick",
    action: `PartnerFormButtonClick`,
    label: `PartnerFormButtonClick`,
  });
};

//-- Prospective partner page view
export const partnerPageViewEvent = () => {
  triggerGAEvent({
    category: "PageView",
    action: `PartnerPageView`,
    label: `PartnerPageView`,
  });
};

//-- Refer a friend click event
export const inviteFriendButtonClickEvent = () => {
  triggerGAEvent({
    category: "CTAclick",
    action: `InviteFriendButtonClick`,
    label: `InviteFriendButtonClick`,
  });
};

export const triggerInvestCTAClick = (symbol: string) => {
  triggerGAEvent({
    category: "CTAclick",
    action: `InvestCTAClick-${symbol}`,
    label: `InvestCTAClick-${symbol}`,
  });
};
